.page {
  display: flex;
  width: 100vw;

  @media (--medium) {
    display: unset;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
}

.content {
  margin-top: var(--Spacing-032);
  min-width: 1156px;

  @media (--medium) {
    min-width: 100%;
  }
}

.content-span {
  --content-max-width: 1256px;

  max-width: var(--content-max-width);

  &.full-width {
    max-width: 100%;
  }

  @media (--medium) {
    max-width: 100%;
  }
}

.content-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 8px 48px 32px;
  position: relative;

  @media (--small) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.header {
  --header-shadow: hsla(131deg 10% 79% / 5%) 0 0.5rem 0.5rem -0.5rem;

  /* box-shadow: var(--header-shadow); */
  box-sizing: content-box;
  padding: var(--Spacing-016) var(--Spacing-048);
  z-index: 5;

  @media (--small) {
    padding: var(--Spacing-016);
  }

  .header-buttton {
    margin: 0 -12px;
  }

  .header-right {
    align-items: center;
    display: flex;
    gap: var(--Spacing-024);
    justify-content: end;
    width: max-content;
  }
}

@supports (animation-timeline: scroll()) {
  .header {
    animation: scroll-shadow linear both;
    animation-range: 0 5ex;
    animation-timeline: scroll();
  }

  @keyframes scroll-shadow {
    from {
      box-shadow: none;
    }

    to {
      box-shadow: var(--header-shadow);
    }
  }
}

.drawer-button {
  @media (min-width: 1440px) {
    display: none;
  }
}

.drawer-nav {
  padding: 16px 8px;
}

.drawer-profile {
  padding: 16px;
}
