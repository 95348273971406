body {
  textarea.MuiInputBase-input {
    padding: 0;
  }

  .rdt_Table {
    .rdt_TableCell {
      align-self: center;
      white-space: pre-wrap;
    }

    .rdt_TableRow {
      &.disabledRow {
        background-color: var(--BackgroundDisabledEmphasisLow);

        &,
        p {
          color: var(--TextIconDisabled) !important;
        }
      }
    }
  }

  .rmdp-wrapper {
    &,
    .rmdp-month-picker,
    .rmdp-year-picker {
      background-color: var(--BackgroundBase1) !important;
    }

    .rmdp-arrow-container {
      &:not(.disabled) {
        cursor: pointer;

        &:hover {
          background-color: var(--BackgroundBrandLight);
          box-shadow: none;
        }

        .rmdp-arrow {
          border-color: var(--BorderInteractiveNeutral);
        }
      }
    }

    .rmdp-day span {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .rmdp-panel-body {
      span {
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        width: calc(100% - 28px);
      }

      li {
        background-color: var(--BackgroundBase3);
        color: var(--TextIconPrimary);

        button {
          background-color: var(--BackgroundBrand);
          color: var(--TextIconInverse);
          top: 49%;
        }

        span {
          cursor: initial !important;
        }
      }
    }

    .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
      background-color: var(--BackgroundBrandLight) !important;
      color: var(--TextIconInteractiveBlack) !important;
    }

    .rmdp-day {
      &.rmdp-deactive {
        color: var(--TextIconInteractiveNeutral);
      }

      &.rmdp-disabled {
        color: var(--TextIconDisabled);
      }

      &.rmdp-today:not(.rmdp-selected) {
        span {
          background-color: transparent;
          border: 2px solid var(--BorderNeutralSubtle);
          color: var(--TextIconPrimary);
        }
      }
    }

    .rmdp-header-values,
    .rmdp-panel-header,
    .rmdp-week-day,
    .rmdp-day:not(.rmdp-deactive) {
      color: var(--TextIconPrimary);
    }

    .rmdp-week-day {
      color: var(--TextIconInteractive);
    }

    .rmdp-day.rmdp-selected span:not(.highlight) {
      background-color: var(--BackgroundBrand);
      box-shadow: none;
      color: var(--TextIconInverse);
    }
  }
}
